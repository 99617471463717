import(/* webpackMode: "eager" */ "/home/runner/work/holibob/holibob/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.0_babel-plugin-_zfcyeqlipnmu4cuewjxo55kvim/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/holibob/holibob/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.0_babel-plugin-_zfcyeqlipnmu4cuewjxo55kvim/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/holibob/holibob/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.0_babel-plugin-_zfcyeqlipnmu4cuewjxo55kvim/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/holibob/holibob/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.0_babel-plugin-_zfcyeqlipnmu4cuewjxo55kvim/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/holibob/holibob/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.0_babel-plugin-_zfcyeqlipnmu4cuewjxo55kvim/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/holibob/holibob/node_modules/.pnpm/next@14.2.3_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.0_babel-plugin-_zfcyeqlipnmu4cuewjxo55kvim/node_modules/next/dist/client/components/render-from-template-context.js");
